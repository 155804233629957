import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../global/LoadingSpinner';
import MapboxPlaceAutocomplete from '../../global/MapboxPlaceAutocomplete';
import SearchIcon from '../../icons/SearchIcon';
import { server } from '../../../utils/server';

import '../../../styles/components/global/CtaRealAddress.scss';

class CtaRealAddress extends React.Component {

  constructor (params) {
    super(params);

    this.state = {
      address: this.props.address,
      loading: false,
      errors: {
        address:  false,
        verified: false
      }
    };

    this.validateAddressAndContinue = this.validateAddressAndContinue.bind(this);
    this.setAddress = this.setAddress.bind(this);
  }

  trackEvent (category, action, label, value) {
    try {
      let tracking = typeof(noJQ) !== "undefined" && noJQ ? modules.import('modules-wo-jquery/tracking') : modules.import('modules/tracking');
      tracking.trackEvent(category, action, label, value, true);
    } catch (err) { console.error(err); }
  }

  setAddress (geocode) {
    const cleanedAddress = geocode.formatted_address.replace(', USA', '');

    this.setState({
      address: cleanedAddress,
      geocode
    }, () => {
      this.validateAddressAndContinue('autocomplete');
    });
  }

  validateAddressAndContinue (eventSource) {
    if (!this.props.api) {
      return () => {
        if (eventSource === 'submit_clicked') {
          this.props.callback('response', {geocode: this.state.geocode, address: this.state.address, property_uuid: null});
        }
      };
    }
      let address = this.state.address || '';
      let verifiedAddress = false;
      let inputVal = document.getElementById(this.props.id).value;

      this.setState({loading: true});

      if (inputVal.length > 0) {
        address = inputVal;
      }

      let errors = {
        address: !address,
        verified: verifiedAddress
      };

      if (!errors.address && !errors.verified) {
        server.post(this.props.api, {
          address: address
        }).then(res => {
          verifiedAddress = res.full_address && res.full_address.length > 0;

          if (verifiedAddress) {
            this.trackEvent('cta', eventSource, this.props.trackPagePosition, res.full_address);
            if(this.props.trackPageName === 'SimpleSale') {
                this.trackEvent('SimpleSale', 'Submit', 'AddressToBeginQuiz', 0);
            }
            this.props.callback('response', {geocode: this.state.geocode, property_uuid: res.property_uuid});
          } else {
            errors.address = true;
            this.setState({errors});
          }
        }).catch(err => {
          errors.address = true;
          errors.verified = false;
          this.setState({errors});
        }).finally(()=> {
          this.setState({loading: false});
        });
      } else {
        this.setState({loading: false});
      }

      this.setState({errors});
  }

  renderButton () {
    const { showLoading, openNewTab, buttonText } = this.props;
    return showLoading && this.state.loading && !openNewTab ? <LoadingSpinner/> : buttonText;
  }

  render () {
    const { id, placeHolder, inputColor, types, showSearchIcon } = this.props;
    const { loading, address, errors } = this.state;
    let className = 'button explore-btn';

    if (loading) {
      className += ' loading';
    }

    return (
      <div className={`cta-real-address-component ${errors.address ? 'error' : ''}`}>
        { showSearchIcon && <div className="cta-real-address-component__search-icon"><SearchIcon /></div> }
        <MapboxPlaceAutocomplete
          id={ id }
          value={ address }
          placeHolder={ placeHolder }
          onSelect={ this.setAddress }
          inputColor={ inputColor }
          types={ types }
          inputClass={showSearchIcon ? "cta-real-address-component__aainput" : "" } />
        <div className="error-messages">
            { ( types && types.indexOf('place') !== -1) ? (
              <span className="validity">
                Please enter a valid city and state. <br/>Ex: San Francisco, CA
              </span>
            ) : (
              <span className="validity">
                Please enter a complete address. <br/>Ex: 123 Main Street Unit 2, City Name, CA 55555
              </span>
            )}
        </div>
        <a onClick={()=>{this.validateAddressAndContinue('submit_clicked')}} className={className}>
          { this.renderButton() }
        </a>
      </div>
    );
  }
}

CtaRealAddress.propTypes = {
  id: PropTypes.string.isRequired,
  address: PropTypes.string,
  showLoading: PropTypes.bool,
  api: PropTypes.string,
  callback: PropTypes.func.isRequired,
  trackPagePosition: PropTypes.string.isRequired,
  trackPageName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  placeHolder: PropTypes.string,
  showSearchIcon: PropTypes.bool
};

// not including id as a default prop because the id varies, whereas api stays the same
CtaRealAddress.defaultProps = {
  address: "",
  showLoading: false,
  api: "/simple-sale/validate_address",
  buttonText: "Get estimate",
  placeHolder: "Enter property address",
  showSearchIcon: false
};

export default CtaRealAddress
